<script>
import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue'
import { formatDate } from '/~/utils/format/date'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import { useSwiper } from '/~/composables/base/use-swiper'
import { usePhotoId } from '/~/composables/photo-ids'

export default {
  name: 'drawer-photo-id-details',
  components: {
    BasePlaceholder,
    BaseAsidePage,
    BaseLoader,
    BaseIcon,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    sliderOptionsOverride: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { isPhotoIdLoading, photoId, fetchPhotoId, fetchPhotoIdImage } =
      usePhotoId()

    const fetchedImages = ref([])

    const {
      swiper,
      swiperContainer,
      activeIndex,
      initSwiper: _initSwiper,
      destroySwiper,
    } = useSwiper(
      computed(() => ({
        slidesPerView: 'auto',
        initialSlide: 0,
        allowTouchMove: fetchedImages.value.length > 2,
        spaceBetween: 16,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
        navigation: {
          nextEl: `[data-id="${props.id}"][data-button="next"]`,
          prevEl: `[data-id="${props.id}"][data-button="prev"]`,
          disabledClass: 'opacity-0 cursor-default',
        },
        ...props.sliderOptionsOverride,
      }))
    )

    function fetchImages() {
      fetchedImages.value.splice(0)

      for (const image of photoId.value.images) {
        const fetchedImage = {
          src: '',
          alt: image.name,
          hash: image.hash,
        }

        fetchedImages.value.push(fetchedImage)

        fetchPhotoIdImage(image.hash).then((blob) => {
          fetchedImage.src = window.URL.createObjectURL(blob)
        })
      }

      nextTick(initSwiper)
    }

    function initSwiper() {
      if (fetchedImages.value?.length > 0) {
        _initSwiper()
      }
    }

    watch(photoId, fetchImages)

    watch(swiperContainer, initSwiper)

    if (!props.id) {
      emit('empty')
    } else {
      if (!photoId.value) {
        fetchPhotoId(props.id)
          .then(() => fetchImages())
          .catch(() => emit('empty'))
      } else {
        fetchImages()
      }
    }

    onBeforeUnmount(() => {
      setTimeout(
        destroySwiper,
        400 // /src/styles/transitions.css - .menu-slide-leave-active - transition-duration: 0.4s
      )
    })

    return {
      isPhotoIdLoading,
      photoId,
      fetchedImages,
      swiperContainer,
      swiper,
      activeIndex,
      formatDate,
    }
  },
}
</script>

<template>
  <base-aside-page :title="`View ${photoId ? photoId.type : ''}`" no-padding>
    <base-loader v-if="isPhotoIdLoading" size="lg" class="py-[88px]" />
    <template v-else>
      <div ref="swiperContainer" class="swiper-container relative my-5 w-full">
        <div class="swiper-wrapper flex min-h-[174px] items-center">
          <div
            v-for="(item, idx) in fetchedImages"
            :key="item.id"
            class="swiper-slide h-full max-h-[120px] w-full max-w-48"
            :class="[
              idx === activeIndex &&
                'swiper-slide-active max-h-[174px] max-w-[300px]',
            ]"
            style="transition: all 500ms"
          >
            <img
              v-if="item.src"
              v-bind="item"
              class="mx-auto max-h-full max-w-full overflow-hidden rounded-lg object-contain"
            />
            <base-placeholder
              v-else
              rounded
              theme="dark"
              type="fill"
              class="h-full w-full"
            />
          </div>
        </div>
        <div
          v-show="swiper"
          :data-id="id"
          data-button="prev"
          role="button"
          class="absolute top-1/2 z-10 ml-5 hidden -translate-y-1/2 transform rounded-full bg-white p-[5px] md:flex"
        >
          <base-icon svg="chevron-left" size="md" />
        </div>
        <div
          v-show="swiper"
          :data-id="id"
          data-button="next"
          role="button"
          class="absolute right-0 top-1/2 z-10 mr-5 hidden -translate-y-1/2 transform rounded-full bg-white p-[5px] md:flex"
        >
          <base-icon svg="chevron-right" size="md" />
        </div>
      </div>
      <div class="p-5">
        <div class="mb-2.5 font-bold text-eonx-neutral-600">ID Details</div>
        <dl
          class="grid grid-cols-2 gap-x-[5px] gap-y-2.5 rounded-lg bg-light p-5"
        >
          <dt class="text-eonx-neutral-600">Country</dt>
          <dd class="font-bold">
            {{ photoId.country }}
          </dd>

          <dt class="text-eonx-neutral-600">
            {{ `${photoId.type} Number` }}
          </dt>
          <dd class="font-bold">
            {{ photoId.number }}
          </dd>

          <dt class="text-eonx-neutral-600">Family Name</dt>
          <dd class="font-bold capitalize">
            {{ photoId.lastName }}
          </dd>

          <dt class="text-eonx-neutral-600">Given Name</dt>
          <dd class="font-bold capitalize">
            {{ photoId.firstName }}
          </dd>

          <dt class="text-eonx-neutral-600">Date of Birth</dt>
          <dd class="font-bold">
            {{ formatDate('daymonthyear', photoId.dateOfBirth) }}
          </dd>

          <dt class="text-eonx-neutral-600">Issuing Authority</dt>
          <dd class="font-bold">
            {{ photoId.countryOfIssue }}
          </dd>

          <dt class="text-eonx-neutral-600">
            {{ `${photoId.type} Expiry` }}
          </dt>
          <dd class="font-bold">
            {{ formatDate('daymonthyear', photoId.expiryDate) }}
          </dd>
        </dl>
      </div>
    </template>
  </base-aside-page>
</template>
